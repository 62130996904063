<template>
<Breadcrumb :urls="urls"  :translationloaded="translationloaded" :translate="translate"></Breadcrumb>
<WhatsAppButton></WhatsAppButton>
  <div class="privacy-info-sec">
    <div class="container">
      <div class="">
          <div class="col-md-12 mb-5 about">
                 <template v-if="pageContent!=''"> 
                          <h3>{{pageContent.title}}</h3>
                      <div class="mt-5"  v-html="pageContent.content"></div>
                  </template>
               
              
          </div>
         
      </div>
    </div>
    </div>
    
</template>
<script>
import Breadcrumb from "../components/Breadcrumb";
import helper from "@/helper/helper";
import WhatsAppButton from "@/components/WhatsAppButton";
import {mapGetters} from 'vuex';
export default {
     name: "CmsPages",
     props: [ "changed_url",'translationloaded',
      'translate'],
     watch:{
          $route() {  
               let parameters = this.$route.params;
               this.pageKey =parameters.key;
               this.loadPageContent(); 
          },
          getStoreCmsPages(){
               let parameters = this.$route.params;
               this.pageKey =parameters.key;
               this.loadPageContent(); 
         
           }
          },
    data() {
    return {
      urls: [
        {
          path: "/",
          name: "Home",
        },
        
      ],
       lang:'',
       pageKey:'',
       pageContent:''
      
     
    };
  },
  components: {
    Breadcrumb,
    WhatsAppButton
   
  },
  methods:{
      loadPageContent(){
          if( this.getStoreCmsPages!=''){
                  this.getStoreCmsPages.forEach((item)=>{

                    if(item.pageKey==this.pageKey){

                        this.pageContent =item;
                        let bredCrumbs ={
                            "path":`/pages/${item.pageKey}`,
                            "name":item.title
                        }
                        this.urls[1]=bredCrumbs;
                      }

                    });
                }
      }
  },
  mounted(){
       helper.backtoTop();
       this.lang = localStorage.getItem('lang');
       let parameters = this.$route.params;
       this.pageKey =parameters.key;
       this.loadPageContent();
  },
  computed:{
    ...mapGetters(['getStoreCmsPages']),
  }
}
</script>
<style >
   .about h3{
        text-align: center;
        width:100%;
        margin-top: 30px!important;
    }
    .about p {
    font-size: 1.6rem;
    line-height: 1.8em;
    color: #666;
    }
    .about h4{
        margin-top: 20px;
    }
    .about .privacy_ul{
        list-style-type: disc;
    }
    .privacy_ul li{
        margin-left: 30px;
    }
    .privacy_ol{
        list-style-type: lower-roman;
    }
    .privacy_ol li{
         margin-left: 30px;
    }

</style>